import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {graphql} from "gatsby"


export const data = graphql`query HomeQuery {
    pound: file(relativePath: {eq: "iconPound.png"}) {
      publicURL
    },
    planner: file(relativePath: {eq: "NemoBudgetPlanner.pdf"}){
        publicURL
    }
  }
  `;

const IndexPage = ({ data }) => (
  <Layout>
    <Seo title="Home" lang={'en-gb'} />
      <div id="exHome" className="cfx">
          <div id="row1" className="cfx">
              <div className="twoCol cfx">
                  <div className="bdrTop"></div>
                  <div className="bdrMid cfx">
                      <section className="col1">
                          <div className="inr">
                              <div className="headingArea cfx">
                                  <div className="bubble purple cfx">
                                      <div className="inr">
                                          <h1>Welcome to Nemo</h1>
                                      </div>
                                      <div className="bot"></div>
                                  </div>
                                  <div className="intro">
                                      <h2>Service Update</h2>
                                      <p>The option to make your payments through Nemo’s website is no longer available. If you usually make your payment this way, we would encourage you to make your payment through your bank by using the details below:</p>
                                      <ul>
                                          <li>Sort Code: 20 18 23</li>
                                          <li>Account Number: 20069604</li>
                                          <li>Payment Reference: Your Nemo account number</li>
                                      </ul>
                                      <p>In the event you are unable to make your payment that way, you can call us on 0800 612 9982 (9am to 5pm, Monday to Friday) where we can process your payment.</p>
                                      <p>Nemo no longer offers second charge mortgages to new customers or further advances to our existing customers. We remain 100% committed to providing our existing customers with exceptional service.</p>
                                      <p><strong>
                                      Nemo customers considering opening their homes to Ukraine refugees as part of the ‘Homes for Ukraine’ government scheme, please visit our <a href="/ukraine-crisis">Ukraine Crisis</a> page for further information.
                                      </strong></p>
                                  </div>
                              </div>
                          </div>
                      </section>
                      <aside className="col2">
                          <div className="bubble purple cfx">
                              <div className="inr">
                                  <h2>Customer Services</h2>
                              </div>
                              <div className="bot"></div>
                          </div>
                          <p>If you would like to find out how your Nemo mortgage works or find out more information
                              about&nbsp;remortgaging or settling your account, then click on the links below:</p>
                          <ul>
                              <li>
                                  <a id="main_0_rptTopRightList_ctl01_lnkLinkCol1" className="arr" href="/customer-service/moving-home">How your Nemo mortgage works</a>
                              </li>

                              <li>
                                  <a id="main_0_rptTopRightList_ctl02_lnkLinkCol1" className="arr" href="/customer-service/remortgaging">What to do if you want to remortgage </a>
                              </li>

                              <li>
                                  <a id="main_0_rptTopRightList_ctl03_lnkLinkCol1" className="arr" href="/customer-service/settle-my-second-charge-mortgage">Settle my Nemo mortgage early</a>
                              </li>
                          </ul>
                          <p>
                              <a href="/customer-service" className="btn round purple">
                                  See more options
                                  <span className="tl"></span>
                                  <span className="tr"></span>
                                  <span className="bl"></span>
                                  <span className="br"></span>
                              </a>
                          </p>
                      </aside>
                  </div>
                  <div className="bdrBot"></div>
              </div>
          </div>
          <div id="row2" className="cfx">
              <div className="twoColEven cfx">
                    <section className="col1 col">
                        <div className="mid">
                            <h2>Budgeting Planner</h2>
                            <p className="MsoNormal" style={{margin: '0cm 0cm 8pt'}}>
                                Understanding your outgoings is the first step to help you manage them.  It 
                                allows you to see where your money goes and where you could spend less.
                            <br /><br />
                                You can use our free budget planner to help you work out your budget.
                            </p>
                            <a href="/help-with-repayments/budget-planner-intro" className="btn floatRight">
                                Visit our Budget Planner
                                <span className="tl"></span>
                                <span className="tr"></span>
                                <span className="bl"></span>
                                <span className="br"></span>
                            </a>
                        </div>
                    </section>
                    <section className="col2 col">
                        <div className="mid">
                            <h2>Financial difficulty</h2>
                            <p className="MsoNormal" style={{margin: '0cm 0cm 8pt'}}>We understand managing your finances can be 
                            difficult. If you’re facing financial hardship, 
                            please let us know so we can support you. 
                            <br /><br />
                            Nemo Personal Finance is a valued subsidiary of
                            the Principality Building Society Group. Visit 
                            Principality’s customer support hub for more 
                            information.</p>
                            <a href="https://www.principality.co.uk/about-us/our-community/customer-help-and-support" className="btn floatRight">
                                Visit Customer Support Hub
                                <span className="tl"></span>
                                <span className="tr"></span>
                                <span className="bl"></span>
                                <span className="br"></span>
                            </a>
                        </div>
                    </section>
              </div>
          </div>
      </div>
  </Layout>
)

export default IndexPage
